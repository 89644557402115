import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMsal, useIsAuthenticated, useAccount } from "@azure/msal-react";

import { AppContext } from "../../AppContext";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Typography from "@mui/material/Typography";

import Assessment from "@mui/icons-material/Assessment";
import Schema from "@mui/icons-material/Schema";

import DataLoading from "./../DataLoading";
import "../../index.css";

const Home = () => {
  const isAuthenticated = useIsAuthenticated();
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const navigate = useNavigate();
  const { embeddingData } = useContext(AppContext);

  if (!isAuthenticated) {
    return (
      <Container maxWidth={false} style={{ backgroundColor: "#f3f2f1", height: "calc(100vh - 44px)" }}>
        {/*<div className="BoxContentGroup">*/}
        {/*  <div className="db-RegisterAndLoginBackground-background">*/}
        {/*    <div className="db-RegisterAndLoginBackground-grayBackground"></div>*/}
        {/*    <div className="db-RegisterAndLoginBackground-verticalLine db-RegisterAndLoginBackground-verticalLine--first"></div>*/}
        {/*    <div className="db-RegisterAndLoginBackground-verticalLine db-RegisterAndLoginBackground-verticalLine--second"></div>*/}
        {/*    <div className="db-RegisterAndLoginBackground-verticalLine db-RegisterAndLoginBackground-verticalLine--third"></div>*/}
        {/*    <div className="db-RegisterAndLoginBackground-verticalLine db-RegisterAndLoginBackground-verticalLine--fourth"></div>*/}
        {/*    <div className="db-RegisterAndLoginBackground-verticalLine db-RegisterAndLoginBackground-verticalLine--fifth"></div>*/}
        {/*    <div className="db-RegisterAndLoginBackground-firstLeftStripe"></div>*/}
        {/*    <div className="db-RegisterAndLoginBackground-secondLeftStripe left-strip-bottom-color"></div>*/}
        {/*    <div className="db-RegisterAndLoginBackground-firstAndSecondLeftStripeBlend left-strip-top-color"></div>*/}
        {/*    <div className="db-RegisterAndLoginBackground-firstRightStripe right-strip-color"></div>*/}
        {/*  </div>*/}
        {/*  <div className="BoxContentGroupInner">*/}
        {/*    <h1 className="headingPage">Saki Health</h1>*/}
        {/*    <form className="registerBoxLayout">*/}
        {/*      <h2 className="box-heading">Sign in to your account</h2>*/}
        {/*      <div>*/}
        {/*        <div className="fieldLabel">Email</div>*/}
        {/*        <input className="custom-input" />*/}
        {/*      </div>*/}
        {/*      <div className="h-32"></div>*/}
        {/*      <div>*/}
        {/*        <div className="flex-content">*/}
        {/*          <div className="fieldLabel">Password</div>*/}
        {/*          <div className="fieldLinkBlue">Forgot your password?</div>*/}
        {/*        </div>*/}
        {/*        <input className="custom-input" />*/}
        {/*      </div>*/}
        {/*      <div className="h-32"></div>*/}
        {/*      <div className="buttonTheme">Continue</div>*/}
        {/*      <div className="h-32"></div>*/}
        {/*    </form>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <Typography
          variant="h5"
          component="h1"
          sx={{ my: 3 }}
          style={{
            fontSize: "36px",
            fontWeight: "600",
            display: "flex",
            paddingTop: "15%",
            justifyContent: "center",
            textAlign: "center",
            color: 'navy'
          }}
        >
          Saki Health
        </Typography>
        <Typography variant='h5' component="h2" sx={{ my: 3 }} style={{ textAlign: 'center' }}>Empower your decisions with exclusive insights on provider prices.</Typography>
        {/* <Alert sx={{ border: 1, padding: 2, mx: 2 }} severity='info' >Click the <strong>LOGIN</strong> button in the upper right to get started.</Alert>  */}
      </Container>
    );
  }

  if (isAuthenticated && embeddingData.user && !embeddingData.tenantName) {
    return (
      <Container maxWidth={false}>
        <Typography
          variant="h5"
          component="h1"
          sx={{ my: 3 }}
          style={{ fontSize: "36px", fontWeight: "600" }}
        >
          Welcome to Saki Health
        </Typography>
        {/* <Typography variant='h5' component="h2" sx={{ my: 3 }} >Welcome to the App-Owns-Data Starter Kit</Typography> */}
        <Alert sx={{ border: 1, padding: 2, mx: 2 }} severity="warning">
          You user account has not been assigned to a tenant. You will not have
          access to ny reports until your user account has been assigned to a
          tenant.
        </Alert>
      </Container>
    );
  }

  if (embeddingData.workspaceArtifactsLoading) {
    // return <DataLoading />;

     return  <div id="content-overlay" style={{
        position: 'fixed',
        top: 70,
        bottom: 10,
        right: 160,
        left: 160,
        height: '90vh',
        backgroundColor: 'white',  // Plain white background
        zIndex: 9999,  // Ensure it's on top of all other elements
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '24px',  // Adjust text size
        color: '#333',  // Text color (dark grey)
      }}>
        Loading data<span className="dots">.</span>
        <style>
          {`
            @keyframes dot-pulse {
              0% { content: ''; }
              33% { content: '.'; }
              66% { content: '..'; }
              100% { content: '...'; }
            }
            
            .dots::after {
              display: inline-block;
              content: '';
              animation: dot-pulse 1.5s infinite steps(1, end);
            }
          `}
        </style>
      </div>
   

  } else {
    return (

      <Container maxWidth={false}>
        <Container maxWidth="xl">
          {/* <Typography
            variant="h5"
            component="h1"
            sx={{ my: 3 }}
            style={{ fontSize: "36px", fontWeight: "600" }}
          >
            Welcome to Saki Health
          </Typography> */}
          {/* <Typography variant='h5' component="h2" sx={{ my: 3 }} >Welcome to the App-Owns-Data Starter Kit</Typography> */}

          {/* <Alert sx={{ border: 1 }} severity="info" >
            You have logged in successfully. Please, use the left navigation
            menu to navigate to the reports.
          </Alert> */}


          {/* <Typography variant='h6' component="h4" sx={{ mt: 2, mb: "6px" }} >Login Session Info:</Typography>

          <TableContainer component={Paper} sx={{ border: 1, backgroundColor: "#EEEEEE" }} >
            <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small" >
              <TableBody>
                <TableRow key={"username"} >
                  <TableCell scope="row" sx={{ width: "200px" }} >
                    User Login:
                  </TableCell>
                  <TableCell><strong>{account?.username}</strong></TableCell>
                </TableRow>
                <TableRow key={"name"} >
                  <TableCell scope="row" sx={{ width: "200px" }} >
                    User Display Name:
                  </TableCell>
                  <TableCell><strong>{account?.name}</strong></TableCell>
                </TableRow>
                <TableRow key={"tenant"} >
                  <TableCell scope="row" sx={{ width: "200px" }} >
                    Tenant Name:
                  </TableCell>
                  <TableCell><strong>{embeddingData.tenantName}</strong></TableCell>
                </TableRow>
                <TableRow key={"userCanEdit"} >
                  <TableCell scope="row">
                    User can edit content:
                  </TableCell>
                  <TableCell><strong>{String(embeddingData.userCanEdit)}</strong></TableCell>
                </TableRow>
                <TableRow key={"userCanCreate"} >
                  <TableCell scope="row">
                    User can create content:
                  </TableCell>
                  <TableCell><strong>{String(embeddingData.userCanCreate)}</strong></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer> */}

          {/* <Typography variant='h6' component="h4" sx={{ mt: 2, borderBottom: 1, mb: "8px" }} >Tenant Contents</Typography> */}
          <Typography
            variant="h6"
            component="h4"
            sx={{ mt: 2, borderBottom: 1, mb: "8px" }}
          >
            Home
          </Typography>

          <Grid container>

            {embeddingData.reports &&
              embeddingData.reports.map((report) => (
                <Grid item xs={6} sx={{ pr: "10px", pt: "25px", pl: "10px" }} key={report.id}>

                  {/* <Box
                sx={{
                  fontSize: "1.25rem",
                  color: "white",
                  background:
                    "linear-gradient(to bottom, #1E3353, #1E3353, #1E3353 )",
                  padding: "4px",
                  paddingLeft: "12px",
                  borderTopLeftRadius: "4px",
                  borderTopRightRadius: "4px",
                }}
              >
                Reports
              </Box> */}


                  {/* <List
                sx={{
                  backgroundColor: "#EEEEEE",
                  border: "1px solid #CCCCCC",
                  pb: 0,
                }}
              >
                {embeddingData.reports &&
                  embeddingData.reports.map((report) => (
                    <ListItem key={report.id} divider dense>
                      <ListItemButton
                        onClick={() => {
                          navigate("/reports/" + report.id);
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar sx={{ backgroundColor: "#607D8B" }}>
                            <Assessment />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={report.name} />
                      </ListItemButton>
                    </ListItem>
                  ))}
              </List> */}



                  <ListItem sx={{ border: 1, borderRadius: 3, backgroundColor: "#dfebf8" }}>
                    <ListItemButton
                      onClick={() => {
                        navigate("/reports/" + report.id);
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar sx={{ backgroundColor: "#183C60" }}>
                          <Assessment />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText sx={{color: '#183C60'}} primary={report.name} />
                    </ListItemButton>
                  </ListItem>

                </Grid>

              ))}


            {/* <Grid item xs={6} sx={{ pl: "4px" }}>
              <Box sx={{ fontSize: "1.25rem", color: "white", background: "linear-gradient(to bottom, #002677, #002676, #002677 )", padding: "4px", paddingLeft: "12px", borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }} >
                Datasets
              </Box>
              <List sx={{ backgroundColor: "#EEEEEE", border: "1px solid #CCCCCC", pb: 0 }} >
                {embeddingData.datasets &&
                  embeddingData.datasets.map((dataset) => (
                    <ListItem key={dataset.id} divider dense >
                      <ListItemButton onClick={() => { navigate("/reports/" + dataset.id); }}>
                        <ListItemAvatar>
                          <Avatar sx={{ backgroundColor: "#607D8B" }} >
                            <Schema />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={dataset.name} />
                      </ListItemButton>
                    </ListItem>
                  ))}
              </List>
            </Grid> */}
          </Grid>
        </Container>
      </Container>
    );
  }
};

export default Home;
